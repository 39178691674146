import { createWebHistory, createRouter } from "vue-router";
import Home from "@/App.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

// 在全局守卫中设置标题
router.beforeEach((to) => {
  if (to.name === "Home") {
    document.title = "Never leave you alone!";
  }
});

export default router;
