const images = require.context('../assets/img/wallpaper', false, /\.(png|jpe?g|gif|svg)$/);

export default {
    images: images.keys().map(images), // 动态获取所有图片

    getImages(i) {
        return this.images[i % this.images.length];
    },
    length() {
        return this.images.length;
    }
}
