<template>
  <div class="app-footer" :style="{ opacity: footerOpacity }">
    <a href="https://space.bilibili.com/488388823" class="footer-link bilibili" target="_blank">
      bilibili: FIMMLP520
    </a>
    &nbsp;&nbsp;&nbsp;&nbsp;
    <a class="footer-link">
      站长qq: 1547223895
    </a>
    &nbsp;&nbsp;&nbsp;&nbsp;
    <a href="https://beian.miit.gov.cn/" class="footer-link" target="_blank">
      ICP备案: 湘ICP备2023026141号
    </a>
  </div>
</template>

<script>
export default {
  name: 'AppFooter',
  data() {
    return {
      footerOpacity: 1,
    };
  },
  mounted() {
    this.footerOpacity = 1;
    this.setFontSize(); // 设置初始字体大小
    window.addEventListener('resize', this.setFontSize); // 监听窗口调整
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setFontSize); // 移除事件监听器
  },
  methods: {
    setFontSize() {
      const footerLinks = document.querySelectorAll('.footer-link');
      const maxSize = 25; // 最大字体大小
      const newSize = Math.min(window.innerWidth / 35, maxSize); // 根据窗口宽度计算新字体大小

      footerLinks.forEach(link => {
        link.style.fontSize = `${newSize}px`; // 设置新的字体大小
      });
    }
  },
}
</script>

<style scoped>
.footer-link {
  color: #000000;
  text-decoration: none; /* 去除下划线 */
  font-family: "abc";
  line-height: 19px;
  text-shadow: 0 1px 2px #999;
  text-shadow: rgba(218, 218, 218, 0.3) 1px 0 0, 
               rgba(218, 218, 218, 0.3) 0 1px 0, 
               rgba(218, 218, 218, 0.3) -1px 0 0, 
               rgba(218, 218, 218, 0.3) 0 -1px 0;  
  width: 100%;
  height: 10px;
  text-align: center;
}

.bilibili {
  animation: pulse 8s linear infinite alternate;
}

@keyframes pulse {
  0% {
    color: #000000;
    text-shadow: rgba(218, 218, 218, 0.3) 1px 0 0, 
               rgba(218, 218, 218, 0.3) 0 1px 0, 
               rgba(218, 218, 218, 0.3) -1px 0 0, 
               rgba(218, 218, 218, 0.3) 0 -1px 0;  
  }
  100% {
    color: #b46abd;
    text-shadow: rgba(0, 0, 0, 0.3) 1px 0 0, 
               rgba(0, 0, 0, 0.3) 0 1px 0, 
               rgba(0, 0, 0, 0.3) -1px 0 0, 
               rgba(0, 0, 0, 0.3) 0 -1px 0;  
  }
}
</style>
