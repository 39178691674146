<template>
  <div class="container" :class="{ 'font-loading': !fontLoaded, 'font-loaded': fontLoaded }">
    <div class="centered" ref="centered">

      <p>Don't forget.</p>
      <p>Always, somewhere,</p>
      <p>someone is fighting for you.</p>
      <p>As long as you remember her.</p>
      <p>You are not alone.</p>
      <v-btn 
        class="start hover-button" 
        :class="{ 'paused': isHovered }" 
        @mouseenter="isHovered = true" 
        @mouseleave="isHovered = false"
        @click="showAlert"
        variant="tonal">
        <a class="joinUs">
          加入我们
        </a>
        <img
          src="../../public/favicon.png"
          width="24"
          height="24"
        />
      </v-btn>
    </div>

  </div>

</template>

<script>

export default {
  data() {
    return {
      fontLoaded: false,
      isHovered: false,

    };
  },
  mounted() {

    window.addEventListener('resize', this.setFontSize); // 监听窗口调整
    this.$nextTick(() => {
      this.setFontSize(); // 在 DOM 更新后调用
    });
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setFontSize); // 移除事件监听器
  },
  methods: {
    showAlert() {
      alert("\nbilibili: FIMMLP520\n\n本站正在建设, 有意向参与的朋友请联系,站长QQ: 1547223895\n\nThe website is under construction. If you are interested in joining, please contact the webmaster QQ: 1547223895");
    },
    setFontSize() {
      const centered = this.$refs.centered;
      if (!centered) {
        // console.warn('Centered element not found');
        return; // 如果为 null，直接返回
      }
      const maxSize = 33;
      const newSize = Math.min(window.innerWidth / 35, maxSize);
      centered.style.fontSize = `${newSize}px`;
    }
  },
};
</script>

<style scoped>

.joinUs {
  font-family: "abc";
  line-height: 19px;
  text-shadow: 0 1px 2px #999;
  text-shadow: rgba(218, 218, 218, 0.3) 1px 0 0, 
               rgba(218, 218, 218, 0.3) 0 1px 0, 
               rgba(218, 218, 218, 0.3) -1px 0 0, 
               rgba(218, 218, 218, 0.3) 0 -1px 0;  
}

body.font-loading {
  visibility: hidden;
}

body.font-loaded {
  visibility: visible;
}

.container {
  text-align: center;
  color: #000000;
  /* text-shadow: 0 1px 2px #999; */
}

.container .centered {

  display: flex; /* 使用 Flexbox */
  flex-direction: column; /* 垂直排列子元素 */
  align-items: center; /* 水平居中 */

  font-family: "MadokaRunes-2.0";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 33px;
  line-height: 1.5;
  scale: 100%;

  text-shadow: rgba(218, 218, 218, 0.3) 1px 0 0, 
               rgba(218, 218, 218, 0.3) 0 1px 0, 
               rgba(218, 218, 218, 0.3) -1px 0 0, 
               rgba(218, 218, 218, 0.3) 0 -1px 0;  

  z-index: 1;
  opacity: 1;
  transition: opacity 8s ease-in-out;
  z-index: 2;
}

.v-btn {
  margin-top: 20px; /* 调整这个值以增大或减小间距 */
}


.start {
  color: #000000;

  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  padding: 8px;

  text-align: center;
  /* text-shadow: rgba(218, 218, 218, 0.3) 1px 0 0, 
               rgba(218, 218, 218, 0.3) 0 1px 0, 
               rgba(218, 218, 218, 0.3) -1px 0 0, 
               rgba(218, 218, 218, 0.3) 0 -1px 0;   */
  animation: pulse 8s linear infinite alternate;
}

@keyframes pulse {
  0% {
    transform: scale(1);
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  }
  100% {
    color: #b46abd;
    background-color: #f0f0f0;

    transform: scale(1.1);
    box-shadow: 0 0 20px rgba(180, 106, 189, 0.6);
  }
}

.start a {
  display: inline-block;
  transform-origin: center;
  animation: pulse-text 4s linear infinite alternate;
}

@keyframes pulse-text {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.95);
  }
}

.hover-button:hover {
  color: #b46abd;
  background-color: #f0f0f0;
}

.container .centered p {

  margin-bottom: 20px;
  font-weight: bold;
  font-style: italic;
}
</style>
