<template>
  <div class="background-container" @mouseenter="handleMouseEnter" @mouseleave="handleMouseLeave">

    <div class="background-image" :style="backgroundImageStyle" />

    <!-- <img v-if="loadIndex < length" class="background-image-load" :style="backgroundLoadImageStyle" /> -->

  </div>
</template>

<script>
import { defineComponent } from 'vue';
import images from '@/utils/images.js';

export default defineComponent({
  data() {
    return {
      index: 0,
      loadIndex: 1,
      fontsLoaded: false,
      loading: true,
      backgroundOpacity: 1, // 新增的透明度状态
      fadingOut: true, // 控制动画方向

      backgroundLoaded: false,

      imgs: [], // 预加载图片的数组
      length: 0, // 初始化为0，后面将更新

      startLonding: true,
      animationPlay: "paused",
    };
  },
  computed: {
    backgroundStyle() {
      return {
        backgroundImage: this.length > 0 ? `url("${this.imgs[this.index].src}")` : '',
      };
    },
    backgroundImageStyle() {
      return {
        ...this.backgroundStyle,
        animationPlayState: this.animationPlay
      };
    },
    backgroundLoadImageStyle(){
      return {
        backgroundImage: this.length > 0 && this.loadIndex < this.length ? `url("${this.imgs[this.loadIndex].src}")` : '',
      };
    },
  },
  methods: {
    startSlideshow() {

      let timeout = 40000;
      
      const changeImage = () => {

        this.index = (this.index + 1) % images.length();
        this.length = images.length();

        // if(this.loadIndex < this.length){
        //   console.log("loadIndex", this.loadIndex);
        //   this.loadIndex = this.loadIndex + 1;
        // }

        this.loading = false; // 结束加载

        setTimeout(changeImage, timeout);
        if (timeout === 20000) {
          timeout = 40000;
        }
      };

      changeImage();
    },
    handleMouseEnter() {
      // console.log('鼠标放入');
    },
    handleMouseLeave() {
      // console.log('鼠标放出');
    },
  },
  mounted() {
    
    this.length = images.length();

    const preloadImages = (index) => {
      if (index < this.length) {
        const image = new Image();
        image.src = images.getImages(index);
        this.imgs.push(image); // 存储预加载的图片

        // 图片加载完成后，6 秒后加载下一张
        image.onload = () => {
          setTimeout(() => {
            preloadImages(index + 1);
          }, 8000);
        };
      }
    };


    const imagefirst = new Image();
    imagefirst.src = images.getImages(0);
    this.imgs.push(imagefirst);

    if(1 < this.length){

      setTimeout(() => {
        const imageSecond = new Image();
        imageSecond.src = images.getImages(1);
        this.imgs.push(imageSecond);
      },10000)

      setTimeout(() => {
        preloadImages(2);
      }, 18000);

    }

    const image = this.imgs[this.index];
    image.onload = () => {
      this.loading = false;
      this.$emit('background-loaded');
      this.animationPlay = "running";

      this.$nextTick(() => {
        setTimeout(() => {
          this.startSlideshow();
        }, 20000);
      });
    };
  },
});
</script>

<style scoped>

  /* @font-face {
    font-family: "MadokaRunes-2.0";
    src: url("/public/assets/title/MadokaRunes-2.0.ttf");
  }
  @font-face {
    font-family: "abc";
    src: url("/public/assets/title/abc.ttf");
  } */

.background-container {
  background-image: url("../assets/img/star.jpg");
  background-size: 30%;
  background-repeat: repeat;
  transition: all 0.3s ease-in-out;
  animation: scrollBackgroundtmsgBox 200s linear infinite;
  position: fixed; 
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
}

.background-image {
  height: 100%;
  width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  animation: fadeImage 20s linear infinite alternate;
  transition: opacity 1s ease;
}

.background-image-load {
  opacity: 0; 
  /* display: none; */
}

@keyframes fadeImage {
  0% { opacity: 1; }
  25% { opacity: 1; }
  75% { opacity: 0; }
  100% { opacity: 0; }
}

@keyframes scrollBackgroundtmsgBox {
  from {
    background-position: 100% 100%;
  }
  to {
    background-position: 0 0;
  }
}

</style>
