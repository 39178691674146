<template>

  <div id="app" v-title="'Never leave you alone!'">
    <div  class="wrapper loading">

      <div class="header"></div>
      <starthome class="section"></starthome>
      <appFooter class="footer"></appFooter>

    </div>

    
    <background class="loading"/>
  </div>

</template>

<script>

import WebFont from 'webfontloader';

import Background from './pages/Backgrounds.vue';
import StartHome from './components/StartHome.vue'
import AppFooter from './components/Footer.vue';

export default {
  name: 'App',
  components: {
    'background': Background,
    'starthome': StartHome,
    'appFooter': AppFooter,
  },
  data() {
    return {
      fontsLoaded: false,
      backgroundLoaded: false,
      isVisible: false,
    };
  },
  methods: {
    handleFontsLoaded() {
      // console.log('Fonts loaded');
      this.fontsLoaded = true;
    },
    handleBackgroundLoaded() {
      // console.log('Image loaded');
      this.backgroundLoaded = true;
    }
  },
  mounted() {

    setTimeout(() => {
      this.isVisible = true; // 10秒后显示内容
    }, 5000); // 10秒

    // WebFont.load({
    //   custom: {
    //     families: ['abc','MadokaRunes-2.0',],
    //     urls: [
    //     require('@/assets/type/abc.ttf'),
    //       require('@/assets/type/MadokaRunes-2.0.ttf'),
    //     ]
    //   },
    //   active: this.handleFontsLoaded
    // });

    const imagesToLoad = 2;
    let imagesLoaded = 0;

    const handleImageLoad = () => {
      imagesLoaded += 1;
      if (imagesLoaded === imagesToLoad) {
        this.handleBackgroundLoaded();
      }
    };

    const image1 = new Image();
    image1.src = require('@/assets/img/wallpaper/01.jpg');
    image1.onload = handleImageLoad;

    const image2 = new Image();
    image2.src = require('@/assets/img/star.jpg');
    image2.onload = handleImageLoad;
  }
}
</script>

<style>

  @font-face {
    font-family: "MadokaRunes-2.0";
    src: url("@/assets/type/MadokaRunes-2.0.ttf");
  }
  @font-face {
    font-family: "abc";
    src: url("@/assets/type/abc.ttf");
  }

  .loading {
    animation:load 8s;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(218, 218, 218, 0.05) 100%);
  }

  ::-webkit-scrollbar {
    width: 0 !important;
  }
  ::-webkit-scrollbar {
    width: 0 !important;
    height: 0;
  }


#app {

  .wrapper{
    display: flex;
    flex-direction: column;
    min-height: 110%;
  }
  .section {
    flex: 1;
    aspect-ratio: 16 / 9;
    width: 100%;
    max-height: 100%;
    overflow: hidden;
  }

  .footer{
    flex: 0;
    text-align: center;
  }


  position:absolute;
  top:0;
  right:0;
  bottom:0;
  left:0;


}

@keyframes load {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
</style>
